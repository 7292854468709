<template>
  <MainLayout
    :loading="loading"
    v-bind="$props"
    :actions="actions"
    :show-actions="hasPermissionForEditingDelivery"
    v-on="$listeners"
    @action="actionsHandler"
  >
    <template #header>
      <div>
        <h1>{{ $t('eventMapModal.deliveryCard.header') }}</h1>
        <div>{{ deliveryWithDifferenceItems.supplier.name || $t('commons.unknown') }}</div>
        <div>{{ formatDate(deliveryWithDifferenceItems.date) || $t('commons.unknownDate') }}</div>
      </div>
    </template>
    <template #content>
      <div class="h-100 overflow-scroll">
        <Boxes
          v-if="business"
          :has-permission-for-editing-delivery="hasPermissionForEditingDelivery"
          :delivery="deliveryWithDifferenceItems"
          :business="business"
        />
        <ItemsTable
          :items="deliveryWithDifferenceItems.products"
          :delivery-id="deliveryWithDifferenceItems.id"
          :supplier-products="supplierProducts"
          :items-editable="hasPermissionForEditingDelivery"
          @refetch="refetch"
          @update="productToUpdate = $event"
          @solve-diff="handleSolveDiff"
        />
      </div>
      <UpdateProductModal
        v-if="productToUpdate"
        :product="productToUpdate.product"
        :value="productToUpdate.value"
        :active-tab="productToUpdate.activeTab"
        :update-type="productToUpdate.updateType"
        @update="handleUpdate"
        @close="productToUpdate = null"
      />
      <DeliveryDifferenceModal
        v-if="diffDeliveryItemId"
        :delivery-item-id="diffDeliveryItemId"
        :delivery="deliveryWithDifferenceItems"
        :business="business"
        @refetch="refetch"
        @close="diffDeliveryItemId = null"
      />
    </template>
  </MainLayout>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { omit, clone, flatten } from 'ramda';

import { useDeliveryItemCreate, useDeliveryItemPatch, useDeliveryItemDelete } from '@/modules/delivery';
import { options } from '@/locale/dateConfig';
import { useCreateBillingFromDocument, useUncertainBillings } from '@/modules/billing';
import { useReconciliationTemplate } from '@/modules/reconciliation';
import { DOCUMENT_TYPES } from '@/modules/document/types';
import { useUserPermissions } from '@/modules/auth';

import { useDeliveryData } from '../../compositions/delivery/useDeliveryData';
import { useBillings } from '../../compositions/billing';
import { UpdateProductModal } from '../../commons/components';
import { Boxes, ItemsTable, DeliveryDifferenceModal } from './components';
import MainLayout from '../MainLayout';
import { USER_NEW_PERMISSIONS_LIST } from '@/permissions';
import { useBusinessById } from '../../compositions';

const mapToTableItem = (item, differences) => {
  const mainProductDiffs = differences.filter(
    (diff) => diff.product.id === item.product.id && diff.customerValue * item.quantity >= 0
  );

  return {
    ...item,
    differences: mainProductDiffs,
    items: item.items?.map((associatedItem) => ({
      ...associatedItem,
      differences: flatten(
        mainProductDiffs
          .filter((mainProductDiff) => mainProductDiff.items?.length)
          .map((mainProductDiff) =>
            mainProductDiff.items.filter((diff) => {
              return diff.product.id === associatedItem.product.id && diff.customerValue * associatedItem.quantity > 0;
            })
          )
      ),
    })),
  };
};

const ACTION_EVENTS = {
  CREATE_BILLING: 'createBilling',
};

export default {
  components: {
    MainLayout,
    Boxes,
    ItemsTable,
    UpdateProductModal,
    DeliveryDifferenceModal,
  },
  props: {
    eventId: { type: String, required: true },
    eventType: { type: String, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const currentId = computed(() => props.eventId);
    const { delivery, supplierProducts, loading, refetch } = useDeliveryData(currentId);
    const { deliveryItemPatch } = useDeliveryItemPatch();
    const { deliveryItemCreate } = useDeliveryItemCreate();
    const { deliveryItemDelete } = useDeliveryItemDelete();
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const hasPermissionForEditingDelivery = isUserPermittedForActiveTenant(USER_NEW_PERMISSIONS_LIST.EDIT_ORDER);

    const businessId = computed(() => delivery.value?.businessId);
    const { business } = useBusinessById(businessId);

    const deliveryWithDifferenceItems = computed(() => {
      if (!delivery.value) return;
      const deliveryClone = omit(['diffs'], clone(delivery.value));
      deliveryClone.differences = clone(delivery.value.diffs);

      deliveryClone.products = deliveryClone.products.map((item) => mapToTableItem(item, deliveryClone.differences));

      const deliveryDifferencesItems = deliveryClone.differences
        .filter((diff) => diff.customerValue === 0)
        .map((diff, index) => {
          const item = {
            differenceItemId: `difference-item-id-${index}-${diff.product.id}`,
            quantity: diff.customerValue,
            product: diff.product,
            supplierValue: diff.supplierValue,
          };

          if (diff.items)
            item.items = diff.items.map((associatedDiff, index) => ({
              differenceItemId: `difference-associated-item-id-${index}-${associatedDiff.product.id}`,
              quantity: associatedDiff.customerValue,
              product: associatedDiff.product,
              supplierValue: diff.supplierValue,
            }));

          return mapToTableItem(item, deliveryClone.differences);
        });

      deliveryClone.products = deliveryClone.products.concat(deliveryDifferencesItems);

      return deliveryClone;
    });

    const productToUpdate = ref(null);

    const handleUpdate = async (data) => {
      try {
        if (productToUpdate.value.type === 'associated') {
          const parentDeliveryItem = delivery.value.products.find(({ items }) => {
            return items?.some(({ id }) => id === productToUpdate.value.id);
          });
          const items = parentDeliveryItem.items.map((item) => ({
            productId: item.product.id,
            quantity: item.id === productToUpdate.value.id ? data.quantity : item.quantity,
          }));
          await deliveryItemPatch({
            deliveryId: props.eventId,
            deliveryItemId: parentDeliveryItem.id,
            data: { quantity: parentDeliveryItem.quantity, items },
          });
        } else if (data.quantity === 0) {
          await deliveryItemDelete({
            deliveryId: props.eventId,
            deliveryItemId: productToUpdate.value.id,
          });
        } else {
          const associatedItems = productToUpdate.value.items?.length
            ? productToUpdate.value.items.map((item) => ({
                productId: item.product.id,
                quantity: (data.quantity * item.quantity) / productToUpdate.value.quantity,
              }))
            : null;

          if (productToUpdate.value.id) {
            await deliveryItemPatch({
              deliveryId: props.eventId,
              deliveryItemId: productToUpdate.value.id,
              data: associatedItems ? { ...data, items: associatedItems } : data,
            });
          } else {
            await deliveryItemCreate({
              deliveryId: props.eventId,
              data: { ...data, productId: productToUpdate.value.product.id },
            });
          }
        }
        productToUpdate.value = null;
        root.$message.success(root.$t('commons.messages.action.success'));
        await refetch();
      } catch (error) {
        console.error(error); //TODO: add to logger when UI will have one
        root.$message.error(root.$t('commons.messages.action.error'));
      }
    };

    const { reconciliationTemplate } = useReconciliationTemplate(
      computed(() => ({
        businessId: businessId.value,
        supplierId: delivery.value?.supplierId,
      }))
    );
    const { billings: relatedBillingsByDocumentId } = useBillings(
      computed(() => ({
        businessId: hasPermissionForEditingDelivery.value ? businessId.value : null,
        eventRef: delivery.value?.eventReferences?.[0].documentId,
      }))
    );
    const { billings: relatedBillingsByOrderId } = useBillings(
      computed(() => ({
        businessId: hasPermissionForEditingDelivery.value ? businessId.value : null,
        orderId: delivery.value?.orderIds?.[0],
      }))
    );
    const { uncertainBillings } = useUncertainBillings(
      computed(() => ({ businessId: hasPermissionForEditingDelivery.value ? delivery.value?.businessId : null }))
    );
    const relevantUncertainBilling = computed(() =>
      uncertainBillings.value.find(({ eventReferences }) =>
        eventReferences.some(({ documentId }) => documentId === delivery.value?.eventReferences?.[0].documentId)
      )
    );

    const getReason = () =>
      business.value?.countryCode !== 'US'
        ? root.$t(`billing.createFromDocument.disableReasons.notValidCountry`)
        : relatedBillingsByDocumentId.value?.length || relevantUncertainBilling.value
        ? root.$t(`billing.createFromDocument.disableReasons.sameSourceBillingExists`)
        : relatedBillingsByOrderId.value?.length
        ? root.$t(`billing.createFromDocument.disableReasons.connectedBillingExists`)
        : null;

    const actions = computed(() => [
      {
        event: ACTION_EVENTS.CREATE_BILLING,
        label: root.$t(`eventMapModal.orderCard.moreActions.${ACTION_EVENTS.CREATE_BILLING}`),
        disabled:
          business.value?.countryCode !== 'US' ||
          !!relatedBillingsByOrderId.value?.length ||
          !!relatedBillingsByDocumentId.value?.length ||
          !!relevantUncertainBilling.value,
        tooltipText: getReason(),
      },
    ]);

    const { createBillingFromDocument, onDone } = useCreateBillingFromDocument();
    onDone(refetch);

    const handleCreateBilling = async () => {
      const createBilling = async () => {
        const loader = root.$loading();
        try {
          const {
            data: { billingCreateFromDocument: created },
          } = await createBillingFromDocument({
            documentId: delivery.value.eventReferences[0].documentId,
            businessId: businessId.value,
          });
          if (created.id) {
            if (created.type === 'billing') {
              root.$message.success(root.$t(`billing.createFromDocument.messages.${created.type}`));
            } else {
              root
                .$alert(
                  root.$t(`billing.createFromDocument.messages.${created.type}`),
                  root.$t('billing.createFromDocument.confirm.title'),
                  {
                    confirmButtonText: root.$t('commons.apply'),
                  }
                )
                .catch(() => null);
            }
          } else root.$message.error(root.$t('billing.createFromDocument.messages.error'));
        } catch {
          root.$message.error(root.$t('billing.createFromDocument.messages.error'));
        } finally {
          loader.close();
        }
      };
      const {
        value: {
          eventReferences: [{ document }],
        },
      } = delivery;
      const { DELIVERY_NOTE, GOODS_RETURN_NOTE, PURCHASE_ORDER } = DOCUMENT_TYPES;
      const isDeliveryDocument = document.type === DELIVERY_NOTE || document.type === GOODS_RETURN_NOTE;
      const isPurchaseOrder = document.type === PURCHASE_ORDER;
      if (
        (isDeliveryDocument && reconciliationTemplate.value.createBillingFrom !== 'deliveryDocument') ||
        (isPurchaseOrder && reconciliationTemplate.value.createBillingFrom !== 'purchaseOrder')
      ) {
        root
          .$confirm(
            root.$t('billing.createFromDocument.confirm.text'),
            root.$t('billing.createFromDocument.confirm.title'),
            {
              confirmButtonText: root.$t('commons.apply'),
              cancelButtonText: root.$t('commons.cancel'),
              confirmButtonClass: 'el-button--primary',
              cancelButtonClass: 'el-button--secondary',
            }
          )
          .then(createBilling)
          .catch(() => null);
      } else await createBilling();
    };

    const actionsHandler = (event) => {
      switch (event) {
        case ACTION_EVENTS.CREATE_BILLING: {
          handleCreateBilling();
          break;
        }
        default:
          break;
      }
    };

    return {
      business,
      deliveryWithDifferenceItems,
      hasPermissionForEditingDelivery,
      formatDate: (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, { ...options.long, timeZone: 'UTC' }),
      loading,
      refetch,
      supplierProducts,
      handleUpdate,
      productToUpdate,
      diffDeliveryItemId: ref(null),
      actions,
      actionsHandler,
    };
  },
  methods: {
    handleSolveDiff(item) {
      this.diffDeliveryItemId = item.id ?? item.differenceItemId;
    },
  },
};
</script>

<style lang="scss" src="../../commons/style.scss" scoped />
