<template>
  <div class="event-modal-card-boxes">
    <Box :title="$t('eventMapModal.deliveryCard.deliveredReturnedBox.title')">
      <div class="box-row">
        <ApprovedIcon width="16px" height="16px" />
        <div>
          <div>
            {{
              $t('eventMapModal.deliveryCard.deliveredReturnedBox.deliveredProducts', {
                count: deliveredProductsCount,
              })
            }}
          </div>
          <div class="event-modal-card-date">
            {{ delivery.date ? formatDate(delivery.date) : $t('commons.unknownDate') }}
          </div>
        </div>
      </div>
      <div class="box-row">
        <ReturnedItemIcon width="16px" height="16px" />
        <div>
          <div>
            {{
              $t('eventMapModal.deliveryCard.deliveredReturnedBox.returnedProducts', {
                count: returnedProductsCount,
              })
            }}
          </div>
          <div class="event-modal-card-date">
            {{
              $t('eventMapModal.deliveryCard.deliveredReturnedBox.returnedAtLocation', {
                location: $t('commons.unknown'),
              })
            }}
          </div>
        </div>
      </div>
    </Box>
    <Box :title="$t('eventMapModal.deliveryCard.deliveredDetailsBox.title')">
      <div class="box-row">
        <CalendarIcon width="16px" height="16px" />
        <div>
          <div>
            {{
              $t('eventMapModal.deliveryCard.deliveredDetailsBox.receivedAt', {
                person: $t('commons.unknown'),
                restaurant: business.name || $t('commons.unknown'),
              })
            }}
          </div>
          <div class="event-modal-card-date">
            {{ delivery.date ? formatDate(delivery.date) : $t('commons.unknownDate') }}
          </div>
        </div>
      </div>
      <div class="box-row">
        <ShopIcon width="16px" height="16px" />
        <div>
          <div>
            {{
              $t('eventMapModal.deliveryCard.deliveredDetailsBox.deliveredBy', {
                person: $t('commons.unknown'),
                supplier: supplierName || $t('commons.unknown'),
              })
            }}
          </div>
          <div class="event-modal-card-date">
            {{
              $t('eventMapModal.deliveryCard.deliveredDetailsBox.deliveredAtLocation', {
                location: $t('commons.unknown'),
              })
            }}
          </div>
        </div>
      </div>
    </Box>
    <SummaryBox
      :documents="documents"
      :supplier-name="delivery.supplier.name"
      :customer-name="business.name"
      :type="summaryBoxType"
    >
      <div v-if="summaryBoxType === BOX_TYPE.SUCCESS">
        {{
          $t(`eventMapModal.deliveryCard.alertBox.success.matchingStatus`, { deliveryTypes: summaryBoxDeliveryTypes })
        }}
      </div>
      <template v-if="delivery.imbalances.length">
        <div v-if="deliveredProductsCount">
          {{ $t(`eventMapModal.deliveryCard.alertBox.error.delivered`) }}
        </div>
        <div v-if="returnedProductsCount">
          {{ $t('eventMapModal.deliveryCard.alertBox.error.returned') }}
        </div>
      </template>
      <template v-if="delivery.differences.length">
        <div v-if="isAllDifferencesResolved() !== 'all'">
          {{
            $t(`eventMapModal.deliveryCard.alertBox.warning.matchingStatus`, {
              deliveryTypes: summaryBoxDeliveryTypes,
            })
          }}
        </div>
        <div v-if="isAllDifferencesResolved() === 'all'">
          {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifference') }}
          <span class="text-decoration-line-through">
            {{
              $tc('eventMapModal.deliveryCard.alertBox.warning.existingDiffs', delivery.differences.length, {
                count: delivery.differences.length,
              })
            }}.
          </span>
        </div>
        <div v-else-if="isAllDifferencesResolved() === 'partial'">
          {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifferencePartial') }}
          {{
            $tc(
              'eventMapModal.deliveryCard.alertBox.warning.existingDiffs',
              delivery.differences.filter((d) => !d.resolved).length,
              {
                count: delivery.differences.filter((d) => !d.resolved).length,
              }
            )
          }}
          <span class="text-decoration-line-through">
            {{
              $tc('eventMapModal.deliveryCard.alertBox.warning.existingDiffs', delivery.differences.length, {
                count: delivery.differences.length,
              })
            }}.
          </span>
        </div>
        <div v-else-if="isAllDifferencesResolved() === 'none'">
          {{
            $tc('eventMapModal.deliveryCard.alertBox.warning.existingDiffs', delivery.differences.length, {
              count: delivery.differences.length,
            })
          }}.
        </div>
      </template>
    </SummaryBox>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';

import { ApprovedIcon, ShopIcon, CalendarIcon, ReturnedItemIcon } from '@/assets/icons';
import { options } from '@/locale/dateConfig';

import { SummaryBox } from '../../../commons/components';
import Box, { BOX_TYPE } from '../../../commons/components/Box.vue';

export default {
  name: 'Boxes',
  components: {
    Box,
    SummaryBox,
    ApprovedIcon,
    ReturnedItemIcon,
    CalendarIcon,
    ShopIcon,
  },
  props: {
    delivery: { type: Object, required: true },
    business: { type: Object, required: true },
  },

  setup(props) {
    const root = getCurrentInstance().proxy;

    const deliveredProductsCount = computed(() => props.delivery.products.filter((i) => i.quantity > 0).length);
    const returnedProductsCount = computed(() => props.delivery.products.filter((i) => i.quantity < 0).length);
    const deliveryDifferences = computed(() => props.delivery.differences.filter((d) => !d.resolved));

    const summaryBoxType = computed(() => {
      const unresolvedDifferences = props.delivery?.differences?.filter((diff) => diff.resolved != true);
      if (!props.delivery.imbalances.length && unresolvedDifferences.length === 0) return BOX_TYPE.SUCCESS;
      return props.delivery.imbalances.length
        ? BOX_TYPE.ERROR
        : props.delivery.differences.length
        ? BOX_TYPE.WARNING
        : BOX_TYPE.SUCCESS;
    });

    return {
      formatDate: (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, { ...options.full, timeZone: 'UTC' }),
      documents: props.delivery.eventReferences?.map(({ document }) => document),
      supplierName: computed(() => props?.details?.supplier?.name),
      summaryBoxType,
      summaryBoxDeliveryTypes: computed(() => {
        const deliveryTypes = [];
        switch (summaryBoxType.value) {
          case BOX_TYPE.WARNING:
          case BOX_TYPE.ERROR:
            if (props.delivery.differences.filter((diff) => diff.customerValue >= 0).length)
              deliveryTypes.push(root.$i18n.t('eventMapModal.deliveryCard.alertBox.deliveryTypes.delivered'));
            if (props.delivery.differences.filter((diff) => diff.customerValue < 0).length)
              deliveryTypes.push(root.$i18n.t('eventMapModal.deliveryCard.alertBox.deliveryTypes.returned'));
            return deliveryTypes.join('/');

          case BOX_TYPE.SUCCESS:
            if (deliveredProductsCount.value)
              deliveryTypes.push(root.$i18n.t('eventMapModal.deliveryCard.alertBox.deliveryTypes.delivered'));
            if (returnedProductsCount.value)
              deliveryTypes.push(root.$i18n.t('eventMapModal.deliveryCard.alertBox.deliveryTypes.returned'));
            return deliveryTypes.join('/');
          default:
            return '';
        }
      }),
      deliveredProductsCount,
      returnedProductsCount,
      deliveryDifferences,
      BOX_TYPE,

      isAllDifferencesResolved() {
        if (props.delivery?.differences?.length === 0) return false;
        const unresolvedDifferences = props.delivery?.differences?.filter((diff) => diff.resolved != true);
        const resolvedDifferences = props.delivery?.differences?.filter((diff) => diff.resolved === true);
        if (unresolvedDifferences.length === 0) {
          return 'all';
        }
        if (resolvedDifferences.length === 0 && props.delivery?.differences?.length > 0) {
          return 'none';
        } else {
          return 'partial';
        }
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../../commons/style.scss';
.box-row {
  display: flex;
  margin-bottom: 16px;
  svg {
    color: $svg-gray-color;

    [dir='rtl'] & {
      margin-left: 10px;
    }
    [dir='ltr'] & {
      margin-right: 10px;
    }
  }
}
</style>
